import cp from '@/libs/codeplug.js';
//import misc from '@/libs/misc.js';
function mkview(buffer,type,offset,count){
    let sz = 1; //bytes
    if( type == Uint16Array || type == Int16Array ){
        sz = 2;
    }
    if( type == Uint32Array || type == Int32Array ){
        sz = 4;
    }
    let toffset = offset/sz;

    return new type(buffer).subarray(toffset,toffset+count);
}
class obcf {
    magic = "RTXC";
    magic_int = 0x43585452;
    version_s = "v0.1.0";
    version = {};
    constructor(){
        this.parse_own_version();
        this.storage = new Uint8Array(500);
        this.make_sane();
        this.check_sanity();
        this.raw.header.counts()[0] = 1;
        this.raw.header.counts()[1] = 1;
        this.nice();
    }
    parse_own_version(){
        this.version.major = this.version_s.split(".")[0].replace("v","");
        this.version.minor = this.version_s.split(".")[1];
    }
    recordsizes = {
        "header":88,
        "contacts": 32 + 1 + 6,
        "channels":3+4+4+2+32+32+ 8+ 5,//2, 4, or 5 for the channel info _t's
    }
    raw = {
        header: {
            magic: ()=>{
                console.log(this);
                return mkview(this.storage.buffer, Uint32Array, 0, 2);
            },
            version:()=>{
                return this.storage.subarray(8,8+2);
            },
            author:()=>{
                return this.storage.subarray(10,10+32);
            },
            description:()=>{
                return this.storage.subarray(42,42+32);
            },
            timestamp:()=>{
                return mkview(this.storage.buffer, Uint32Array, 74, 2);
            },
            counts:()=>{
                return mkview(this.storage.buffer, Uint16Array, 82, 3);
            }
        },
        contacts:{
            storage:()=>{
                let b = this.raw.contacts.bounds();
                return this.storage.subarray(b.offset,b.offset+b.size);
            },
            bounds:()=>{
                return {
                    offset: this.recordsizes.header,
                    size: this.raw.header.counts()[0] * this.recordsizes.contacts,
                }
            }
        },
        channels:{
            storage:()=>{
                let b = this.raw.channels.bounds();
                return this.storage.subarray(b.offset,b.offset+b.size);
            },
            bounds:()=>{
                let c = this.raw.contacts.bounds();
                return {
                    offset: c.offset+c.size,
                    size: this.raw.header.counts()[1] * this.recordsizes.channels,
                }
            }
        },
    };
    nice = ()=>{
        const proxy = (recordtype)=>{
            let offset = ["contacts","channels"];
            return {
                set: (target, prop, value, receiver )=>{
                    console.log(target,prop, value,receiver);
                    if( prop == "length" ){
                        return this.raw.header.counts()[ offset.indexOf(recordtype) ] = value;
                    } 
                },
                get: (target, prop, receiver )=>{
                    console.log(target,prop);
                    if( prop == "length" ){
                        return this.raw.header.counts()[ offset.indexOf(recordtype) ];
                    } 
                    const propnum = parseInt(prop);
                    if (propnum >= 0 && propnum < target[recordtype].length ){
                        let sz = this.recordsizes[recordtype];
                        console.log(sz);
                        return this.raw[recordtype].storage().subarray(propnum*sz,propnum*sz+sz);
                    } 
                },
            }
        };
        this.contacts = new Proxy(this, proxy("contacts"));
        this.channels = new Proxy(this, proxy("channels"));
    }

    //TODO: proxy for reading at a super-nice high level
    //based on raw proxy for reading bytes by name?
    make_sane(){
        let magic = this.raw.header.magic();
        magic[0] = this.magic_int;
        let v = this.raw.header.version();
        v[0] = this.version.major;
        v[1] = this.version.minor;
    }
    check_sanity(){
        let magic = cp.bytes2string(Array.from( cp.dissemble_le( this.raw.header.version()[0] ) ) );
        let v = this.raw.header.version();
        let r = [magic == this.magic, v[0] == this.version.major, v[1] == this.version.minor];
        return r.every((x)=>x);
    }
}
export default {
    obcf: obcf
}
