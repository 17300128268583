import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import obcf from './libs/rtxc.js';
import cp from './libs/codeplug.js';
//import './unregisterServiceWorker'
//

window.obcf = obcf;
window.x = new obcf.obcf();
window.cp = cp;
router.afterEach((to,from,failure)=>{
  console.log(to.fullPath);
  //sendToAnalytics(to.fullPath);
})

const a = createApp(App);
a.use(store).use(router).mount('#app');
a.config.globalProperties.$filters = {
  json(v){
    return JSON.stringify(v, null, 2)
  }
}

//TODO explain advantage of storing codeplugs in cloud:
//      great for mobile editing and synchronization
