<template>
  <div hidden>
    <h5>USB Debug log</h5>

  </div>
      <!--should be a simple log of communications between radio and computer for debugging and development-->

</template>
<script>
export default {
  name: "USBDebugging",
  props: [],
  methods: {
  },
}
</script>
<style scoped>
</style>
